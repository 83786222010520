// Menu

$(function() {
  'use strict';

  // Variables
  var $navigation = $('.js-navigation');
  var currentScroll, lastScroll, offset;


  // Init
  $doc.ready(function() {
    currentScroll = $win.scrollTop();
    offset = $navigation.offset().top;

    // Relative
    if(currentScroll < offset) {
      $navigation.removeClass('is-fixed');
    }

    // Fixed
    else {
      $navigation.addClass('is-fixed');
    }
  });


  // Actions
  $('.js-navigation-link').on('click', function(event) {
    event.preventDefault();

    var $link = $(this),
        $item = $link.parents('.js-navigation-item'),
        $sub  = $item.find('.js-navigation-sub');

    if($win.width() < 960 && $sub.length > 0) {
      $item.toggleClass('is-active');
    }
    else {
      var url = $(this).attr('href');

      window.location.href = url;
    }
  });


  // Scroll
  $win.on('scroll', function(event) {
    lastScroll = currentScroll;
    currentScroll = $win.scrollTop();

    // Relative
    if(currentScroll < offset) {
      $navigation.removeClass('is-fixed');
    }

    // Fixed
    else {
      $navigation.addClass('is-fixed');
    }
  });
});
