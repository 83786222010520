// Navigation

$(function() {
  'use strict';

  $('.js-header-toggle').on('click', function() {
    $body.toggleClass(['nav-active', 'no-scroll']);
    $(this).toggleClass('is-active');
  });
});
