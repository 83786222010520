// Locator

$(function() {
  'use strict';

  var pins = {
    primary: {
      default: '/app/themes/default/assets/images/locator/pin-primary.svg',
      active: '/app/themes/default/assets/images/locator/pin-active-primary.svg',
    },
    secondary: {
      default: '/app/themes/default/assets/images/locator/pin-secondary.svg',
      active: '/app/themes/default/assets/images/locator/pin-active-secondary.svg',
    },
    tertiary: {
      default: '/app/themes/default/assets/images/locator/pin-tertiary.svg',
      active: '/app/themes/default/assets/images/locator/pin-active-tertiary.svg',
    }
  };

  var Locator = {
    config: {},

    elements: {
      $root: false,
      $map: false,
      $results: false,
      $filters: false,
      $countrySelect: false,
    },

    map: false,
    markers: [],
    displayedMarkers: [],
    io: false,
    cluster: false,
    mapStyles: [],


    // Populate variables
    __construct: function($root){
      var _ = this;

      _.elements.$root = $root;
      _.elements.$map = $root.find('.js-locator-map');
      _.elements.$results = $root.find('.js-locator-result');
      _.elements.$filters = $root.find('.js-locator-filter');
      _.elements.$countrySelect = $root.find('.js-locator-country');

      return _;
    },

    // Initialize locaotr
    init: function(){
      var _ = this;

      // Create map
      _.map = new google.maps.Map(_.elements.$map.get(0), {
        center: {lat: 0, lng: 0},
        zoom: 2,
        disableDefaultUI: true,
        zoomControl: true,
      });

      // Initialize custom Info Window
      _.io = new InfoOverlay(_.map);

      // Create markers
      _.elements.$results.each(function(){
        var $result = $(this);

        var marker = new google.maps.Marker({
          position: new google.maps.LatLng($result.data('lat'), $result.data('lng')),
          map: _.map,
          icon: {
            url: pins[$result.data('theme')].default,
            scaledSize: new google.maps.Size(18, 27),
          },

          // Custom prop
          activeIcon: {
            url: pins[$result.data('theme')].active,
            scaledSize: new google.maps.Size(50, 50),
            anchor: new google.maps.Point(25, 34)
          },
          $ioContent: $result.children(),
          group: $result.data('type'),
        });

        marker.defaultIcon = marker.icon;

        _.markers.push(marker);

        // A marker is invisible if we need to cluster it without displaying address (eg: display it)
        if($result.data('invisible') !== true){
          marker.addListener('click', function(){
            _.toggleMarker(this);
          });
        } else {
          marker.setVisible(false);
        }
      });

      // Initialize clusterer
      _.cluster = new MarkerClusterer(_.map, [], {
        styles: [{
          url: '/app/themes/default/assets/images/locator/cluster.svg',
          width: 50,
          height: 50,
          textColor: '#027793',
          textSize: 14,
          fontFamily: 'inherit',
        }]
      });

      // Auto submit form when country change
      _.elements.$countrySelect.on('change', function(){
        $(this).closest('form').submit();
      });

      // Toggle markers by selected filters
      _.elements.$filters.on('change', function(){
          _.toggleGroups();
      });
      _.toggleGroups();


      // Zoom in/out map according to the density of markers
      _.fitBounds();
    },

    // Zoom in/out map according to the density of markers
    fitBounds: function(){
      var _ = this;

      if(_.markers.length){
        var bounds = new google.maps.LatLngBounds();

        for(var i=0; i<_.markers.length; i++){
          bounds.extend(_.markers[i].getPosition());
        }

        _.map.fitBounds(bounds);
      }
    },

    // Toggle markers by selected filters
    toggleGroups: function(){
      var _ = this;
      var currentFilters = [];
      _.displayedMarkers = [];

      // If Infow Window open, close it
      if(_.io.marker)
        _.closeMarker(_.io.marker);

      // Get current filters
      _.elements.$filters.each(function(){
        if($(this).prop('checked') === true)
          currentFilters.push($(this).attr('value'));
      });

      // Display or hide markers
      for(var i=0; i<_.markers.length; i++){
        if(currentFilters.indexOf(_.markers[i].group) !== -1){
          if(_.elements.$results.eq(i).data('invisible') !== true)
            _.markers[i].setVisible(true);
          _.elements.$results.eq(i).show();
          _.displayedMarkers.push(_.markers[i]);
        } else {
          _.markers[i].setVisible(false);
          _.elements.$results.eq(i).hide();
        }
      };

      // Cluster displayed markers
      _.clusterMarkers();
    },

    // Cluster displayed markers
    clusterMarkers: function(){
      var _ = this;

      _.cluster.clearMarkers();
      _.cluster.addMarkers(_.displayedMarkers);
    },

    // Open or close a marker
    toggleMarker: function(marker){
      var _ = this;

      if(_.io.marker && _.io.marker === marker){
        _.closeMarker(marker);
      } else {
        if(_.io.io.style.visibility === 'visible') _.closeMarker(_.io.marker);
        _.openMarker(marker);
      }
    },

    // Open a marker
    openMarker: function(marker){
      var _ = this;

      // Set Icon for active state
      marker.setIcon(marker.activeIcon);

      // Update Info Window content and show it
      _.io.marker = marker;
      _.io.update(marker.position, marker.$ioContent.get(0).cloneNode(true));
      _.io.show();
    },

    // Close a marker
    closeMarker: function(marker){
      var _ = this;

      // Set Icon for default state
      marker.setIcon(marker.defaultIcon);

      // Hide Infow Window
      _.io.hide();
      _.io.marker = null;
    },
  };

  /**
   * Custome InfoWindow
   * https://developers.google.com/maps/documentation/javascript/examples/overlay-popup
   */
  var InfoOverlay;

  function defineInfoOverlay(){
    InfoOverlay = function(map){
      this.io = document.createElement('div');
      this.io.classList.add('c-locator__io');

      this.setMap(map);
    };

    InfoOverlay.prototype = Object.create(google.maps.OverlayView.prototype);

    InfoOverlay.prototype.onAdd = function(){
      this.getPanes().floatPane.appendChild(this.io);
    }

    InfoOverlay.prototype.onRemove = function(){
      if(this.io.parentElement)
        this.io.parentElement.removeChild(this.io);
    }

    InfoOverlay.prototype.draw = function(){
      if(this.position){
        var ioPosition = this.getProjection().fromLatLngToDivPixel(this.position);

        // Hide the popup when it is far out of view.
        var display = Math.abs(ioPosition.x) < 4000 && Math.abs(ioPosition.y) < 4000 ? 'block' : 'none';

        if(display === 'block'){
          this.io.style.left = ioPosition.x + this.io.style.marginLeft + 'px';
          this.io.style.top = ioPosition.y - (this.io.offsetHeight / 2) + 'px';
        }
        if(this.io.style.display !== display){
          this.io.style.display = display;
        }
      }
    }

    InfoOverlay.prototype.update = function(position, content){
      this.position = position;
      this.io.innerHTML = '';
      this.io.appendChild(content);
    }

    InfoOverlay.prototype.show = function(){
      if(this.io){
        this.io.style.visibility = 'visible';
        this.map.panTo(this.position);
        // Center IO instead of marker
        this.map.panBy(this.io.offsetWidth / 2, 0);
      }
    }

    InfoOverlay.prototype.hide = function(){
      if(this.io) this.io.style.visibility = 'hidden';
    }
  }

  // Init multiple locators once GMAP API is loaded
  window.initLocators = function(){
    defineInfoOverlay();

    $('.js-locator').each(function(){
      var locator = Locator.__construct($(this));
      locator.init();
    });
  };

  // Load local Cluster script, then GMAP API
  function initGMap(){
    if($('.js-locator').length){
      var mcScript = document.createElement('script');
      mcScript.type = "text/javascript";
      mcScript.src = $('.js-locator').data('markerclustererplus');
      document.body.appendChild(mcScript);

      mcScript.addEventListener('load', function() {
        if(typeof google === 'undefined'){
          var gScript = document.createElement('script');
          gScript.type = "text/javascript";
          gScript.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyC4Kgfmkppr9FlQN1vSNsYfj_N6WAKvcoI&callback=initLocators&language=" + $('html').attr('lang').substr(0, 2);
          document.body.appendChild(gScript);
        }
      });
    }
  }

  $(document).ready(initGMap);
});
