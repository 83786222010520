// Footer

$(function() {
  'use strict';

  $('.js-footer-toggle').on('click', function(event) {
    event.preventDefault();

    var $toggle = $(this),
        $column = $toggle.parents('.js-footer-column'),
        $items  = $column.find('.js-footer-items');

    if($win.width() < 720 && $items.length > 0) {
      $column.toggleClass('is-active');
    }
    else {
      var url = $(this).attr('href');

      window.location.href = url;
    }
  });

});
