// Shutter

$(function() {
  'use strict';

  $('.js-shutter-toggle').on('click', function() {
    var $toggle  = $(this),
        $shutter = $toggle.parents('.js-shutter');

    $shutter.toggleClass('is-active');
  });

});
