// Carousel

//= require magnific-popup/src/js/core.js
//= require magnific-popup/src/js/image.js
//= require magnific-popup/src/js/iframe.js
//= require magnific-popup/src/js/gallery.js

$(function() {
  'use strict';

  initPopup();
  initCarousels();

  function initCarousels() {
    $('.js-carousel-main-items').slick({
      autoplay: false,
      arrows: false,
      dots: true,
      slidesToShow: 1,
      fade: true,
      infinite: false,
      speed: 150,
      asNavFor: '.js-carousel-controls-items',
      appendDots: '.js-carousel-pager',
      customPaging: function(slider, i){
        return '<button class="c-carousel__page js-carousel-page" data-role="none" role="button" aria-required="false", tabindex="0"><span class="u-visually-hidden">' + (i+1) + '</span></button>'
      },
    });

    $('.js-carousel-controls-items').slick({
      autoplay: false,
      arrows: false,
      dots: false,
      slidesToScroll: 6,
      slidesToShow: 6,
      asNavFor: '.js-carousel-main-items',
      focusOnSelect: true,
      infinite: false,
      variableWidth: true
    });
  }

  function initPopup() {
    var preventClose = false;

    var popupOptions = {
      items: [],
      type: 'image',
      gallery: {
        enabled: true,
        arrowMarkup: '',
        tCounter: '',
      },
      showCloseBtn: false,
      mainClass: 'c-carousel__overlay',
      image: {
        markup: '<div class="mfp-media">\
                  <div class="mfp-figure">\
                    <div class="mfp-img"></div>\
                  </div>\
                </div>',
      },
      iframe: {
        markup: '<div class="mfp-media">\
                  <div class="mfp-iframe-scaler">\
                    <iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>\
                  </div>\
                </div>',
      },
      callbacks: {
        open: function(){
          var mfp = this;
          var $gallery = $('<div class="mfp-gallery"></div>');

          // Create overlay's gallery
          $(mfp.items).each(function(){
            var $thumb = typeof this.data !== 'undefined' ? this.data.thumb : this.thumb;
            $gallery.append($thumb);

            $thumb.on('click', function(e){
              preventClose = true;
              var index = mfp.thumbs.index($(this));
              mfp.goTo(index);
            })
          });

          mfp.content.after($gallery);

          mfp.thumbs = mfp.contentContainer.find('.js-carousel-thumb');
          mfp.thumbs.eq(mfp.index).addClass('is-active');

          // Override close function to control preventClose flag
          $.magnificPopup.instance.close = function(){
            if(!preventClose)
              $.magnificPopup.proto.close.call(this);
            preventClose = false;
          }
        },
        change: function(){
          if(typeof this.thumbs !== 'undefined')
            this.thumbs.removeClass('is-active').eq(this.index).addClass('is-active');
        }
      }
    };

    // Gallery items
    $('.js-carousel-thumb').each(function(){
      popupOptions.items.push({
        src: $(this).data('media'),
        thumb: $(this).clone(),
        type: $(this).data('type')
      });
    });

    $('.js-carousel-magnify').on('click', function(){
      // Open popup at current media
      $.magnificPopup.open(popupOptions, $('.js-carousel-main-items .slick-slide').index($('.js-carousel-main-items .slick-current')));
    });
  }

});
