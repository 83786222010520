// Slider

$(function() {
  'use strict';

  $('.js-slider-items').slick({
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: true,
    dotsClass: 'c-slider__dots'
  });

  $('.js-slider-more').on('click', function(event) {
    event.preventDefault();

    var $this   = $(this),
        target  = $this.attr('href'),
        $target = $(target);

    if($target.length > 0) {
      $('html, body').animate({
        scrollTop:$(target).offset().top
      }, 600);
      return false;
    }
  });

});
