// Locales

$(function() {
  'use strict';

  $('.js-locales-placeholder').on('click', function(event) {
    event.preventDefault();

    var $this   = $(this),
        $parent = $this.parents('.js-locales'),
        $list   = $parent.find('.js-locales-list');

    $list.toggleClass('is-active');
  });

});
