// Pagination

$(function() {
  'use strict';

  var buttonClass = '.js-ajax-pagination',
      rootClass   = '.js-ajax-pagination-root';

  $(buttonClass).on('click', function(event) {
    event.preventDefault();

    var $button   = $(this),
        url       = $button.attr('href'),
        $root     = $(rootClass);

    // Button processing
    $button.addClass('is-processing');

    $.ajax({
      url: url,
      success: function(html) {
        var $content       = $(html),
            $newContent    = $content.find(rootClass).contents(),
            $newPagination = $content.find(buttonClass);

        // Append new content
        $root.append($newContent);

        // Update more link
        if($newPagination.length > 0) {
          var newUrl = $newPagination.attr('href');
          $button.attr('href', newUrl);
        }
        else {
          $button.hide();
        }

        $button.removeClass('is-processing');
      },
      error: function() {
        $button.removeClass('is-processing');
      }
    });
  });

});
