// Contact

$(function() {
  'use strict';

  // Init contact map once GMAP API is loaded
  window.initContactMap = function(){
    var $map = $('.js-contact-map');
    var lat = 48.858123;
    var lng = 2.304155;

    var map = new google.maps.Map($map.get(0), {
      center: {lat: lat, lng: lng},
      zoom: 14,
      disableDefaultUI: true,
    });

    new google.maps.Marker({
      position: new google.maps.LatLng(lat, lng),
      map: map,
      icon: {
        url: '/app/themes/default/assets/images/locator/pin-secondary.svg',
        scaledSize: new google.maps.Size(33, 49),
      },
    });

    // Center in useful area
    map.panBy(0, parseInt($map.closest('.js-contact-map-container').css('margin-top')) / 2);
  };

  // Load GMAP API
  function initGMap(){
    if($('.js-contact-map').length){
      if(typeof google === 'undefined'){
        var gScript = document.createElement('script');
        gScript.type = "text/javascript";
        gScript.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyC4Kgfmkppr9FlQN1vSNsYfj_N6WAKvcoI&callback=initContactMap&language=" + $('html').attr('lang').substr(0, 2);
        document.body.appendChild(gScript);
      }
    }
  }

  $(document).ready(initGMap);
});
