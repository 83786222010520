// Card Carousel

$(function() {
  'use strict';

  $('.js-card-carousel').each(function(){
    var $root = $(this);
    var $view = $root.find('.js-card-carousel-view');
    var $pager = $root.find('.js-card-carousel-pager');

    var options =  {
      slide: '.js-card-carousel-slide',
      infinite: false,
      rows: 0,
      arrows: false,
      dots: true,
      appendDots: $pager,
      customPaging: function(slider, i){
        return '<button class="c-card-carousel__page js-card-carousel-page" data-role="none" role="button" aria-required="false", tabindex="0"><span class="u-visually-hidden">' + (i+1) + '</span></button>'
      },
      mobileFirst: true,
      responsive: [{
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      }, {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      }],
    };

    $view
      .on('init', function(event, slick){ hideOrShowDots(slick); })
      .on('breakpoint', function(event, slick, breakpoint){ hideOrShowDots(slick); });

    $view.slick(options);
  });

  function hideOrShowDots(slick){
    if(slick.slideCount <= slick.options.slidesToShow) {
      slick.$slider.closest('.js-card-carousel').addClass('hide-pager');
    } else {
      slick.$slider.closest('.js-card-carousel').removeClass('hide-pager');
    }
  }

});
